import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { includes, isEmpty, isNil } from 'ramda';

import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query';

import { ApiResponse } from '@/@types/api-response';

import { useSessionAuth } from '@/context/use-session-provider';

import { ASSESSMENT_STATUS, Assessment } from '@@/ItemAndGrading/entities/assessment';
import { Item } from '@@/ItemAndGrading/entities/item';
import { useGetAssessment } from '@@/ItemAndGrading/hooks/use-assessment-service';
import ItemService from '@@/ItemAndGrading/services/item-service';
import { Retailer } from '@@/StoreAndStoreOperator/entities/retailer';
import RetailerService from '@@/StoreAndStoreOperator/services/retailer-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { ROUTES } from '@/constants';
import { fetchKeys } from '@/constants/fetch-keys';

import FullScreenSpinner from '@/components/full-screen-spinner';

type AssessmentContext = {
  retailer?: { data?: Retailer; isLoading: boolean; isError: boolean };
  assessment?: {
    data?: Assessment;
    isLoading: boolean;
    refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ApiResponse<Assessment>, Error>>;
    isSuccess: boolean;
    isError: boolean;
    error?: Error | null;
  };
  itemId?: string;
  itemData: Item;
  setItemData: (item: Item) => void;
  productName: string;
  eventsParams: { [x: string]: string | number | null | undefined | boolean };
  clearAssessment: () => void;
};

const assessmentContext = createContext<AssessmentContext>({} as AssessmentContext);

export const AssessmentProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const { query, push, pathname } = useRouter();
  const { sessionToken, interceptorId } = useSessionAuth();

  const [itemData, setItemData] = useState<Item>({} as Item);

  const {
    assessmentData,
    isLoading,
    refetchAssessment,
    isGetAssessmentSuccess,
    errorAssessment,
    isGetAssessmentError,
  } = useGetAssessment({
    enabled: !isNil(sessionToken) && !isNil(interceptorId),
  });

  const {
    data: itemFetched,
    isSuccess: isItemSuccess,
    isPending,
  } = useQuery({
    queryKey: [fetchKeys.item.find, assessmentData?.itemId, sessionToken],
    queryFn: () => ItemService.findSessionItem(assessmentData?.itemId as string),
    enabled: !!assessmentData?.itemId,
    retry: 3,
    retryDelay: 500,
  });

  const {
    data: retailerData,
    isLoading: isRetailerLoading,
    isError: isRetailerError,
  } = useQuery({
    queryKey: [fetchKeys.retailer.get, sessionToken],
    queryFn: () => RetailerService.getRetailerSession(),
    retryDelay: 500,
    enabled: !isNil(sessionToken) && !isNil(interceptorId),
  });

  useEffect(() => {
    if (isItemSuccess && itemFetched) setItemData(itemFetched.response);
  }, [isItemSuccess, itemFetched, isPending]);

  useEffect(() => {
    if (!includes(ROUTES.SESSION_SUCCESS, pathname) && assessmentData?.status === ASSESSMENT_STATUS.COMPLETED)
      push(ROUTES.SESSION_SUCCESS);
  }, [assessmentData?.status]);

  useEffect(() => {
    if (!isEmpty(itemData)) storage?.setItem(STORAGE_KEYS.DOJI_ITEM_ID, itemData.id);
  }, [itemData]);

  const eventsParams = {
    id: assessmentData?.id,
    retailerId: assessmentData?.retailerId,
    ...(assessmentData?.itemId ? { itemId: assessmentData?.itemId } : null),
  };

  useEffect(() => {
    if (isRetailerError) push(ROUTES.SESSION_NOT_FOUND);
  }, [isRetailerError]);

  const clearAssessment = () => {
    setItemData({} as Item);
  };

  const values = {
    retailer: {
      data: retailerData?.response,
      isLoading: isRetailerLoading,
      isError: isRetailerError,
    },
    assessment: {
      data: assessmentData,
      isLoading: isLoading,
      refetch: refetchAssessment,
      isSuccess: isGetAssessmentSuccess,
      error: errorAssessment,
      isError: isGetAssessmentError,
    },
    itemData,
    setItemData,
    itemId: storage?.getItem(STORAGE_KEYS.DOJI_ITEM_ID) ?? assessmentData?.itemId ?? itemData?.id,
    productName:
      itemData?.product?.model?.fullModelName ?? storage?.getItem(STORAGE_KEYS.DOJI_PRODUCT_NAME) ?? query?.product,
    eventsParams,
    clearAssessment,
  };

  if (!retailerData?.response && !includes(ROUTES.SESSION_NOT_FOUND, pathname)) return <FullScreenSpinner isVisible />;

  return <assessmentContext.Provider value={values}>{children}</assessmentContext.Provider>;
};

export const useAssessment = () => useContext(assessmentContext);
