import { ApiResponse } from '@/@types/api-response';

import { Assessment } from '@@/ItemAndGrading/entities/assessment';

import { tradeInApi } from '@/config/api';

export default class AssessmentService {
  static async create(itemId?: string) {
    const { data } = await tradeInApi.post<ApiResponse<Assessment>>(`/v1/session/assessments`, {
      itemId: itemId ?? null,
    });
    return data;
  }

  static async getAssessment() {
    const { data } = await tradeInApi.get<ApiResponse<Assessment>>(`/v1/session/assessments/current`);
    return data;
  }

  static async update(itemId?: string) {
    const { data } = await tradeInApi.put<ApiResponse<Assessment>>(`/v1/session/assessments`, {
      itemId: itemId ?? null,
    });
    return data;
  }

  static async finalize(assessmentId: string) {
    const { data } = await tradeInApi.post<ApiResponse<Assessment>>(`/v1/session/assessments/${assessmentId}/finalize`);
    return data;
  }
}
