import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { destroyCookie, setCookie } from 'nookies';
import { includes, isNil } from 'ramda';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { tradeInApi } from '@/config/api';

import { ROUTES } from '@/constants';

import { isEmptyOrNil } from '@/utils';

import FullScreenSpinner from '@/components/full-screen-spinner';

export type SessionData = {
  sessionToken?: string;
  redirectUrl?: string;
};

type SessionContext = {
  sessionToken?: string;
  redirectUrl?: string;

  saveSessionData: (data: SessionData) => void;
  clearSession: () => void;

  interceptorId?: number;
};

const SessionAuthContext = createContext<SessionContext>({} as SessionContext);

export const SessionAuthContextProvider: React.FCWC = ({ children }) => {
  const { query, pathname } = useRouter();

  const localStorage = useLocalStorage();
  const sessionDataStorage = localStorage?.getItem(STORAGE_KEYS.SESSION_DATA);

  const [interceptorId, setInterceptorId] = useState<number>();
  const [sessionData, setSessionData] = useState<SessionData>();

  useEffect(() => {
    if (isEmptyOrNil(sessionData)) {
      if (query?.sessionToken)
        saveSessionData({ sessionToken: query.sessionToken as string, redirectUrl: query?.redirectUrl as string });
      else saveSessionData(sessionDataStorage);
    }
  }, []);

  useEffect(() => {
    if (sessionData?.sessionToken) {
      if (sessionData.sessionToken) setCookie(null, '__doji_session', sessionData.sessionToken, { path: '/' });
      localStorage?.setItem(STORAGE_KEYS.SESSION_DATA, sessionData);
    }
  }, [sessionData, sessionDataStorage]);

  const saveSessionData = (data: SessionData) => {
    setSessionData((prev) => ({ ...prev, ...data }));
  };

  const clearSession = () => {
    localStorage?.clearStorage();
    destroyCookie(null, '__doji_session', { path: '/' });
  };

  useEffect(() => {
    setInterceptorId(undefined);
    if (interceptorId) tradeInApi.interceptors.request.clear();
    if (!sessionData?.sessionToken) return;

    const interceptor = tradeInApi.interceptors.request.use((req) => {
      if (sessionData?.sessionToken) req.headers['Authorization'] = `Bearer ${sessionData?.sessionToken}`;

      return req;
    });

    setInterceptorId(interceptor);
  }, [sessionData?.sessionToken]);

  const values = {
    sessionToken: sessionData?.sessionToken,
    redirectUrl: sessionData?.redirectUrl,
    clearSession,
    saveSessionData,
    interceptorId,
  };

  if ((isNil(interceptorId) || isNil(sessionData?.sessionToken)) && !includes(ROUTES.SESSION_NOT_FOUND, pathname))
    return <FullScreenSpinner isVisible />;

  return <SessionAuthContext.Provider value={values}>{children}</SessionAuthContext.Provider>;
};

export const useSessionAuth = () => useContext(SessionAuthContext);
