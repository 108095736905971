export type Assessment = {
  id: string;
  itemId?: string;
  status: ASSESSMENT_STATUS;
  sessionId: string;
  retailerId: string;
  created: Date;
  updated?: Date;
};

export enum ASSESSMENT_STATUS {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}
